<h1 matDialogTitle>{{ data.message }}</h1>
<!-- <div mat-dialog-content>{{ data.message }}</div> -->
<div class="actions">
    <button
        mat-raised-button
        class="mat-accent mr-16"
        (click)="dialogRef.close(true)"
    >
        Confirm
    </button>
    <button *ngIf="!data.error" mat-stroked-button (click)="dialogRef.close(false)">Cancel</button>
</div>
