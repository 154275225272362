import { environment } from '../environments/environment';

export class ConfiguracionUrl{
  getUrlApi(){//URL API BACK(CON TOKEN)
    return environment.urlApi;
  }

  getUrlApiUsuarios(){//URL API BACK(CON TOKEN)
    return environment.urlApiUsuarios;
  }

  getUrlApiComisiones(){//URL API BACK(CON TOKEN)
    return environment.urlApiComisiones;
  }

  getUrlApiCarga(){//URL API BACK(CON TOKEN)
    return environment.urlApiCarga;
  }

  getUrlApiTemp(){//URL API BACK(CON TOKEN)
    return environment.urlApiTemp;
  }

  getUrlApiImg(){//URL API BACK(CON TOKEN)
    return environment.urlApiImg;
  }

  getUrlApiNav(){//URL API BACK(CON TOKEN)
    return environment.urlApiNav;
  }

  getUrlApiNomina(){//URL API BACK(CON TOKEN)
   return environment.urlApiNomina;
  }     

  getUrlApiMerchant(){//URL API BACK(CON TOKEN)
    return environment.urlApiMerchant;
  }   

  getUrlApiPayrollBack(){//URL API BACK(CON TOKEN)
    return environment.urlApiPayrollBack;
  }

  getUrlApiPayrollTrx(){//URL API BACK(CON TOKEN)
    return environment.urlApiPayrollTrx;
  }

  getUrlApiPayrollTrxCustomLoad(){//URL API BACK(CON TOKEN)
    return environment.urlApiPayrollTrxCustomLoad;
  }

  getUrlBase(){//URL FRONT (ANGULAR)
    return environment.baseUrl;
  }

  getUrlAccion(){//URL API BACK VISA (ACCION/SIN TOKEN)
    return environment.urlAction;
  }
}
