import { Injectable } from '@angular/core';
import { JwtService } from './jwt.service';
import { JwtCognitoPayload } from '../interfaces/jwt-cognito-payload.interface';
import { InicioService } from 'app/inicio-service.service';
import { CognitoRoles } from '../enum/cognito-roles.enum';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class RoleService {
    jwtPayload: JwtCognitoPayload;

    get role(): string {
        return this.jwtPayload ? this.jwtPayload['custom:Rol'] : '';
    }

    get isAdmin(): boolean {
        return +this.role === CognitoRoles.ADMIN;
    }

    get isCarga(): boolean {
        return +this.role === CognitoRoles.CARGA;
    }

    get isAprobador(): boolean {
        return +this.role === CognitoRoles.APROBADOR;
    }

    get isManager(): boolean {
        return +this.role === CognitoRoles.MANAGER;
    }

    get isConsulta(): boolean {
        return +this.role === CognitoRoles.CONSULTA;
    }

    get isIntegrador(): boolean {
        return +this.role === CognitoRoles.INTEGRADOR;
    }

    get isAgente(): boolean {
        return +this.role === CognitoRoles.AGENTE;
    }

    constructor(
        private inicioService: InicioService,
        private jwtService: JwtService<JwtCognitoPayload>,
        private router: Router,
    ) {
        this.jwtService.decode(this.inicioService.tokenAcess);
        this.jwtPayload = this.jwtService.jwt;
    }

    /**
     * @description Redirecciona a la ruta correspondiente según el rol del usuario.
     * @returns void
     */
    navigateToRedirectionRoute(): void {
        this.router.navigate([this.redirectionRouteByRole()]);
    }

    /**
     * @description devuelve a la ruta correspondiente para redireccion según el rol del usuario.
     */
    getRedirectionRoute(): string {
        return this.redirectionRouteByRole();
    }

    /**
     * @description devuelve a la ruta correspondiente para redireccion según el rol del usuario.
     * @returns string
     */
    private redirectionRouteByRole(): string {
        if (this.isAdmin) {
            return '/apps/dashboards/project'
        }

        if (this.isCarga) {
            return '/apps/dashboards/project'
        }

        if (this.isAprobador) {
            return '/apps/dashboards/project'
        }

        if (this.isManager) {
            return '/apps/dashboards/project'
        }

        if (this.isConsulta) {
            return '/apps/dashboards/project'
        }

        if (this.isIntegrador) {
            return '/apps/reportes/reporte'
        }

        if (this.isAgente) {
            return '/apps/nomina/nominas'
        }

        /**
         * @description Si no se cumple ninguna de las condiciones anteriores, se redirecciona a la página de login.
         */
        return '/pages/auth/login'
    }
}
