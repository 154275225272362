import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { config, CognitoIdentityCredentials } from "aws-sdk";
import { CognitoUserPool, CognitoUser, AuthenticationDetails, CognitoUserSession } from 'amazon-cognito-identity-js';
import { AssociateSoftwareTokenPayload, AuthenticateCognitoPayload, GetDevicesPayload, UserPoolData, VerifySoftwareTokenPayload } from '../interfaces/cognito-models.interface';
import { BehaviorSubject } from 'rxjs';
import { ChallengeName, ChallengeNameType } from 'aws-sdk/clients/cognitoidentityserviceprovider';

@Injectable({
    providedIn: 'root'
})
export class CognitoService {
    totpFlow: BehaviorSubject<{ FRIENDLY_DEVICE_NAME: string, username: string }> = new BehaviorSubject<{ FRIENDLY_DEVICE_NAME: string, username: string }>({ FRIENDLY_DEVICE_NAME: '', username: '' });
    totpFlow$ = this.totpFlow.asObservable();

    mfaSetupFlow: BehaviorSubject<{ username: string }> = new BehaviorSubject<{ username: string }>({ username: '' });
    mfaSetupFlow$ = this.mfaSetupFlow.asObservable();

    private userPool = new CognitoUserPool(environment.cognitoPool)

    private _accessToken: string = "";
    private _userloggedIn: boolean = false;
    private _userDetails: any = {};
    private cognitoGetUser: CognitoUser = null;

    public static _REGION = environment.cognitoPool.region;
    public static _IDENTITY_POOL_ID = environment.cognitoPool.identityPoolId;
    public static _USER_POOL_ID = environment.cognitoPool.UserPoolId;
    public static _CLIENT_ID = environment.cognitoPool.ClientId;

    public static _POOL_DATA: UserPoolData = {
        UserPoolId: CognitoService._USER_POOL_ID,
        ClientId: CognitoService._CLIENT_ID
    };

    set accessToken(value: string) {
        this._accessToken = value;
    }

    set userLoggedIn(value: boolean) {
        this._userloggedIn = value;
    }

    get userLoggedIn(): boolean {
        return this._userloggedIn;
    }

    set UserDetails(value: any) {
        this._userDetails = value;
    }

    get UserDetails(): any {
        return this._userDetails;
    }

    get cogUserPool(): CognitoUserPool {
        return this.userPool;
    }

    constructor(public http: HttpClient) { }

    onAuthenticate(data: AuthenticateCognitoPayload): void {
        const CogAuthData = new AuthenticationDetails(data);
        this.cognitoGetUser = this.getCognitoUser(data.Username);

        this.cognitoGetUser.authenticateUser(CogAuthData, {
            newPasswordRequired: err => data.newPasswordRequired(err),
            onSuccess: (result) => this.onLoginSuccess(result, data.onSuccess),
            onFailure: (err) => data.onFailure(err),
            mfaRequired: (challengeName: any, challengeParameters: any) => data.mfaRequired(challengeName, challengeParameters),
            totpRequired: (challengeName: any, challengeParameters: any) => this.isOtpRequired(challengeName, challengeParameters, data.totpRequired),
            customChallenge: (challengeParameters: any) => data.customChallenge(challengeParameters),
            mfaSetup: (challengeName: any, challengeParameters: any) => this.isMfaSetup(challengeName, challengeParameters, data, data.mfaSetup),
            selectMFAType: (challengeName: any, challengeParameters: any) => data.selectMFAType(challengeName, challengeParameters)
        });
    }

    getUserPool(): CognitoUserPool {
        if (environment.cognitoPool.cognito_idp_endpoint) {
            CognitoService._POOL_DATA.endpoint = environment.cognitoPool.cognito_idp_endpoint;
        }

        return new CognitoUserPool(CognitoService._POOL_DATA);
    }

    getCurrentUser(): CognitoUser {
        return this.getUserPool().getCurrentUser();
    }

    getCognitoUser(username: string): CognitoUser {
        return new CognitoUser({ Username: username, Pool: this.getUserPool() })
    }

    /**
     * @method CognitoService.onLoginSuccess
     * @param {CognitoUserSession} userSession - The user session object from cognito
     * @return {void}
     * @description This method is used to set the user details and access token
     */
    private onLoginSuccess(userSession?: CognitoUserSession, onSuccess?: (userSession: any) => void): void {
        this.cognitoGetUser = this.cogUserPool.getCurrentUser();

        if (this.cognitoGetUser != null) {
            this.setSession()
            onSuccess(userSession);
        }
    }

    /**
     * @param {AssociateSoftwareTokenPayload} data
     * @returns { void }
     */
    associateSoftwareToken(data: AssociateSoftwareTokenPayload, username?: string): void {
        if (!this.cognitoGetUser && !username) this.cognitoGetUser = this.cogUserPool.getCurrentUser();
        if (username) this.cognitoGetUser = this.getCognitoUser(username);

        this.setSession()

        try {
            this.cognitoGetUser.associateSoftwareToken({
                onFailure: (err) => data.onFailure(err),
                associateSecretCode: (secretCode) => data.onSecretCode(secretCode)
            })
        } catch (error) {
            data.onFailure(error)
        }
    }

    /**
     * 
     * @param data { VerifySoftwareTokenPayload }
     * @returns { void }
     */
    verifySoftwareToken(data: VerifySoftwareTokenPayload): void {
        if (!this.cognitoGetUser) this.cognitoGetUser = this.cogUserPool.getCurrentUser();
        this.setSession()

        try {
            this.cognitoGetUser.verifySoftwareToken(data.totpCode, this.totpFlow.getValue().FRIENDLY_DEVICE_NAME, {
                onSuccess: (session) => data.onSuccess(session),
                onFailure: (err) => data.onFailure(err)
            })
        } catch (error) {
            data.onFailure(error)
        }
    }

    sendMfaCode(code: string, onSuccess: (session: CognitoUserSession) => void, onFailure: (err: any) => void): void {
        this.cognitoGetUser.sendMFACode(code, {
            onSuccess: (session) => this.onSuccessMfaCode(session, onSuccess),
            onFailure: (err) => onFailure(err),
        }, 'SOFTWARE_TOKEN_MFA')
    }

    onSuccessMfaCode(session: CognitoUserSession, onSuccess: (session: CognitoUserSession) => void): void {
        onSuccess(session)
    }

    /**
     * @param data { GetDevicesPayload }
     * @returns { void }
     */
    getDevices(data: GetDevicesPayload): void {
        if (!this.cognitoGetUser) this.cognitoGetUser = this.cogUserPool.getCurrentUser();
        this.setSession()

        try {
            this.cognitoGetUser.listDevices(data.limit, data?.paginationToken || null, {
                onSuccess: (data) => data.onSuccess(data),
                onFailure: (err) => data.onFailure(err)
            })
        } catch (error) {
            data.onFailure(error)
        }
    }

    /**
     * @method CognitoService.setSession
     * @return {void}
     * @description This method is used to set the session for the user and get the credentials
     */
    private setSession(): void {
        this.cognitoGetUser.getSession((err: Error, result: CognitoUserSession) => {
            if (result) {
                let token = result.getIdToken().getJwtToken();

                let cognitoParams = {
                    IdentityPoolId: environment.cognitoPool.identityPoolId,
                    Logins: {}
                };

                cognitoParams.Logins[environment.cognitoPool.loginsParameter] = token;
                config.region = environment.cognitoPool.region;
                config.credentials = new CognitoIdentityCredentials(cognitoParams);
            }
        })
    }

    /**
     * @method CognitoService.setUserMfaPrefrerence
     * @return {void}
     * @description This method is used to set the user mfa preference
     */
    setUserMfaPrefrerence(): void {
        this.cognitoGetUser.setUserMfaPreference(
            {
                PreferredMfa: false,
                Enabled: false,
            },
            {
                PreferredMfa: true,
                Enabled: true,
            },
            (err, result) => {
                console.log(err, result)
            },
        )
    }

    /**
     * 
     * @param challengeName 
     * @param challengeParameters 
     * @param onSuccess 
     * @returns { void }
     */
    private isOtpRequired(challengeName: ChallengeName, challengeParameters: any, onSuccess: (challengeName: ChallengeNameType) => void): void {
        this.totpFlow.next({ FRIENDLY_DEVICE_NAME: challengeParameters.FRIENDLY_DEVICE_NAME, username: this.cognitoGetUser.getUsername() })
        onSuccess(challengeName)
    }

    /**
     * 
     * @param challengeName 
     * @param challengeParameters 
     * @param onSuccess 
     * @returns { void }
     */
    private isMfaSetup(challengeName: ChallengeName, challengeParameters: any, data: AuthenticateCognitoPayload, onSuccess: (challengeName: ChallengeNameType, challengeParameters: any) => void): void {
        this.mfaSetupFlow.next({ username: data.Username })
        onSuccess(challengeName, challengeName)
    }
}                               
