export const environment = {
    production: false,
    hmr: false,
    baseUrl: "https://dev.payout.monnetpayments.com/",
    //baseUrl: "http://localhost:4200/",
    urlApi: "https://dev.apipo.monnetpayments.com/v1/",
    urlApiPayrollTrx: "https://dev.monnetpayments.com/ms-payroll-trx/",
    urlApiPayrollTrxCustomLoad: "https://dev.monnetpayments.com/ms-payroll-trx/",
    urlApiPayrollBack: "https://dev.monnetpayments.com/ms-payroll-back/",
    urlApiMerchant: "https://dev.monnetpayments.com/ms-merchaoont-payout/commerce/",
    urlApiComisiones: "https://dev.monnetpayments.com/ms-payroll-trx/",
    urlApiNomina: "https://dev.apipo.monnetpayments.com/v1/",
    urlApiNav: "https://dev.apipo.monnetpayments.com/v1/menu",
    urlApiImg: "https://dev.payout.monnetpayments.com/logocomercio/",
    urlApiTemp: "https://dev.apipo.monnetpayments.com/v1/",
    urlApiCarga: "https://x8qehyw099.execute-api.us-west-2.amazonaws.com/prod",
    urlApiUsuarios: "https://dev.internal.payout.monnetpayments.com/v1/",
    urlAction: "https://1lus0iktme.execute-api.us-west-2.amazonaws.com/",
    urlPayoutFraudnet: "https://dev.payout.fraudnet.monnet.io/ms-payout-fraudnet/",
    authorization: '993d5882ed0cbfe4bcea932eee366611b84624dfb0ac4230b23ec431cf4b0cd0',
    freshDeskUrlApi: 'https://monnetpayments.freshdesk.com/api/v2/tickets',
    freshDeskApiKey: 'q1XUepz7IWG0UBt1o6G',
    cognitoPool: {
        region: 'us-east-1',
        UserPoolId: "us-east-1_fYwhYq0Uf",
        ClientId: "2m9akh37r5sd85h8ble540atp4",

        identityPoolId: 'us-east-1:347ca4af-a8da-478b-b9b3-1290b5de4620',
        rekognitionBucket: 'rekognition-pics',
        albumName: "usercontent",
        bucketRegion: 'us-east-1',

        ddbTableName: 'LoginTrail',
        loginsParameter: 'cognito-idp.us-east-1.amazonaws.com/us-east-1_fYwhYq0Uf',

        cognito_idp_endpoint: '',
        cognito_identity_endpoint: '',
        sts_endpoint: '',
        dynamodb_endpoint: '',
        s3_endpoint: ''

    },
    TAG_ANALYTICS: 'G-M5WGV0FZY',
};
