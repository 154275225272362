import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IGetBankBatchPayload } from 'app/interfaces/get-bank-batch-payload.interface';
import { IGroupBankBatchPayload } from 'app/interfaces/group-bank-batch-request.interface';
import { UserLoginService } from 'app/main/pages/services/login-user.services';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { ConfiguracionUrl } from '../../../../configuracionUrl';
import { BankBatchFileResponse } from 'app/main/models/BankBatchFileResponse';
import Swal from 'sweetalert2';

interface IBankBatch {
}

@Injectable({
    providedIn: 'root'
})

export class BankService {

    private batchBankUrl: string = `${environment.urlApiPayrollBack}api/batch/`
    private idtoken: string;

    private headers: HttpHeaders
    private headersBatch: HttpHeaders
    private headersVerification: HttpHeaders
    private headersWithBearerPrefix: HttpHeaders
    private baseUrl: ConfiguracionUrl

    private paisIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>('')

    public paidId$: Observable<string> = this.paisIdSubject.asObservable()

    dataSubject: BehaviorSubject<IBankBatch> = new BehaviorSubject<IBankBatch>('')

    data$: Observable<IBankBatch> = this.dataSubject.asObservable()

    public set dataValue(value: IBankBatch) {
        this.dataSubject.next(value)
    }

    public set countryId(value: string) {
        this.paisIdSubject.next(value)
    }

    public get countryId(): string {
        return this.paisIdSubject.getValue()
    }

    public get dataValue(): IBankBatch {
        return this.dataSubject.getValue()
    }

    public get data(): Observable<IBankBatch> {
        return this.data$
    }

    constructor(
        private httpClient: HttpClient,
        private getParametroCognito: UserLoginService
    ) {
        this.baseUrl = new ConfiguracionUrl();
        this.idtoken = this.getParametroCognito.renovarToken();

        this.headersBatch = new HttpHeaders({
            'authorization': environment.authorization
        });

        this.headers = new HttpHeaders({
            'Content-type': 'application/json;charset=utf8',
            'Authorization': this.idtoken
        });

        this.headersVerification = new HttpHeaders({
            'Content-type': 'application/json;charset=utf8',
            'verification': environment.authorization
        });

        this.headersWithBearerPrefix = new HttpHeaders({
            'Content-type': 'application/json;charset=utf8',
            'Authorization': `Bearer ${this.idtoken}`
        })
    }

    getBankBatch(params?: IGetBankBatchPayload): Observable<IBankBatch> {
        let param = new HttpParams()

        param = param.append('bankId', String(params.bankId))
        param = param.set("countryId", String(params.countryId))
        param = param.set("currency", String(params.currency))
        param = param.set("transferType", String(params.transferType))
        param = param.set("startDate", String(params.startDate))
        param = param.set("endDate", String(params.endDate))
        param = param.set("merchantIds", String(params.merchantIds))
        param = param.set("page", String(params.page))
        param = param.set("size", String(params.size))
        param = param.set("sort", String('creationDate,desc'))

        return this.httpClient.get<IBankBatch[]>(`${this.batchBankUrl}payrollBank`, { headers: this.headersWithBearerPrefix, params: param })
            .pipe(
                take(1),
                map((result: IBankBatch) => {
                    this.dataValue = result
                    return result
                }),
                catchError((err: Error) => {
                    return throwError(err.message)
                })
            )
    }

    groupBankBatch(payload: IGroupBankBatchPayload, merchantId: string): Observable<BankBatchFileResponse> {
        return this.httpClient.post<any>(`${environment.urlApiPayrollTrx}batch-grouping/${merchantId}`, payload, { headers: this.headersBatch })
            .pipe(
                catchError((err: Error) => {
                    return throwError(err.message)
                })
            )
    }

    downloadBatchFile(fileName: string): Observable<any> {
        const excelFile: boolean = fileName.includes('.xlsx') ? true : false;
        const type: string = excelFile ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'text/plain';

        return this.httpClient.get(`${environment.urlApiPayrollTrx}api/bank-file/download?filename=${fileName}`, { responseType: 'blob' })
            .pipe(
                tap((result) => {
                    const blob: Blob = new Blob([result], { type: type });
                    const url = window.URL.createObjectURL(blob);
                    const element = document.createElement('a');
                    element.href = url;
                    element.download = `${fileName}`;

                    document.body.appendChild(element);

                    element.click();

                    document.body.removeChild(element);

                    window.URL.revokeObjectURL(url);
                }),
            )
    }

    getBatchByCountry(countryId: string): Observable<any> {
        return this.httpClient.get(`${environment.urlApiPayrollBack}merchants/batch-merchant/${countryId}`, { headers: this.headersWithBearerPrefix })
            .pipe(
                take(1),
                map((result: any) => {
                    localStorage.setItem('comercioId', String(result.comercioid));
                    return result
                }),
                catchError((err: Error) => {
                    return throwError(err)
                })
            )
    }

    formatMerchantByCountryData(data: any) {
        return {
            comercioData: {
                "comercioid": data.comercioid,
                "nombre": data.name,
                "imglogo": " ",
                "paisId": data.countryId,
                "customLoad": data.customLoad,
                "flagUniqueWallet": data.flgUniqueWallet,
                "flagSendH2h": data.h2hSendFlag,
                "monedas": data.currencyList,
            },
            nombreGrupo: data.name
        }
    }


}
