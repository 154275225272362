import { NgModule } from '@angular/core';

import { FuseSidebarComponent } from './sidebar.component';
import { SupportModule } from 'app/main/apps/shared/support/support.module';

@NgModule({
    declarations: [
        FuseSidebarComponent
    ],
    imports: [
        SupportModule,
    ],
    exports: [
        FuseSidebarComponent
    ]
})
export class FuseSidebarModule {
}
