import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { SupportService } from '../../services/support.service';
import { SupportFormComponent } from '../support-form/support-form.component';
import { CreateHelpDeskTicketPayload } from '../../interfaces/support.interfaces';
import { initWidget } from '../../utils/create-widget';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-support-container',
    templateUrl: './support-container.component.html',
    styleUrls: ['./support-container.component.scss']
})
export class SupportContainerComponent implements OnInit, OnDestroy {
    @ViewChild("supportFormComponent", { static: true })
    supportFormComponent: SupportFormComponent;

    @Input() metadata: Object = {};

    @Output() onClosePopup: EventEmitter<void> = new EventEmitter<void>();

    successSubscription: Subscription[] = [];

    isLoading: boolean = false

    private initialValue = initWidget

    get initialFormValue(): CreateHelpDeskTicketPayload {
        return this.initialValue;
    }

    get isValid(): boolean {
        return this.supportFormComponent.form.valid;
    }

    get isInvalid(): boolean {
        return this.supportFormComponent.form.invalid;
    }

    set changeFormValue(value: CreateHelpDeskTicketPayload) {
        this.supportFormComponent.form.setValue(value);
    }

    get formValue(): CreateHelpDeskTicketPayload['helpdesk_ticket'] {
        return this.supportFormComponent.form.value;
    }

    constructor(
        private supportService: SupportService,
        private alertService: MatSnackBar,
        private translate: TranslateService,
    ) { }

    ngOnInit(): void {
        const successSubscription = this.supportService.ticketDispatched$.subscribe(() => {
            this.alertService.open(this.translate.instant('WIDGET_SUPPORT.SUCCESS_MESSAGE'), 'OK', {
                duration: 5000,
            })
            this.isLoading = false
            this.onClosePopup.emit()
            this.resetForm()
        })

        const failureSubscription = this.supportService.ticketDispatchError$.subscribe(() => {
            this.alertService.open(this.translate.instant('WIDGET_SUPPORT.ERROR_MESSAGE'), 'OK', {
                duration: 5000,
            })
            this.isLoading = false
        })

        this.successSubscription.push(successSubscription, failureSubscription)
    }

    resetForm(): void {
        this.supportFormComponent.form.reset()
        this.supportFormComponent.fileUploaderComponent.deleteImages()
    }

    validateForm(): void {
        this.supportFormComponent.form.markAllAsTouched()
    }

    createTicket(): void {
        if (this.isInvalid) {
            this.alertService.open(this.translate.instant('WIDGET_SUPPORT.COMPLETE_REQUIRED_FIELD'), 'OK', {
                duration: 5000,
            })

            this.validateForm()
            return
        }

        if (this.supportFormComponent.maxSizeError) {
            this.alertService.open(this.translate.instant('WIDGET_SUPPORT.MAX_FILE_SIZE_MESSAGE'), 'OK', {
                duration: 5000,
            })
            return
        }

        if (this.supportFormComponent.maxFilesError) {
            this.alertService.open(this.translate.instant('WIDGET_SUPPORT.MAX_FILE_QUANTITY_MESSAGE'), 'OK', {
                duration: 5000,
            })
            return
        }

        this.isLoading = true

        this.supportService.dispatchTicketRequest = {
            helpdesk_ticket: {
                description_html: this.formValue.description_html,
                subject: this.metadata['merchantId'] ? (this.metadata['merchantId'] + ' ' + this.formValue.subject) : this.formValue.subject,
                email: this.formValue.email,
                requester_name: this.formValue.requester_name,
                custom_field: {
                    cf_fsm_contact_name_1921958: this.formValue.requester_name,
                },
                atachments: this.formValue.atachments,
            } as CreateHelpDeskTicketPayload['helpdesk_ticket']
        }
    }

    ngOnDestroy(): void {
        this.successSubscription.forEach((subscription: Subscription) => {
            subscription.unsubscribe()
        })
    }
}
