import { SharedService } from './../../../services/shared-service.service';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import "rxjs/Rx";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";

import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { CognitoService } from 'app/services/authentication/services/cognito.service';
import { NewPasswordUser } from '../authentication/forgot/forgot.component';

import * as AWS from "aws-sdk/global";
import { CognitoCallback, LoggedInCallback } from 'app/services/authentication/interfaces/cognito-models.interface';
import { ChallengeNameType } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import { ChallengeParameters } from 'app/inicio-service.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class UserLoginService {

    private _accessToken: string = "";
    private _userloggedIn: boolean = false;
    private _userDetails: any = {};

    constructor(public http: HttpClient, public cognitoUtil: CognitoService,
        public _sharedService: SharedService, private router: Router,) {

    }

    isAuthenticated(callback: LoggedInCallback) {
        if (callback == null)
            throw ("UserLoginService: Callback in isAuthenticated() cannot be null");

        let cognitoUser = this.cognitoUtil.getCurrentUser();

        if (cognitoUser != null) {
            cognitoUser.getSession(function (err, session) {
                if (err) {
                    // console.log("UserLoginService: Couldn't get the session: " + err, err.stack);
                    callback.isLoggedIn(err, false);
                }
                else {
                    //  console.log("UserLoginService: Session is " + session.isValid());
                    callback.isLoggedIn(err, session.isValid());
                }
            });
        } else {
            //  console.log("UserLoginService: can't retrieve the current user");
            callback.isLoggedIn("Can't retrieve the CurrentUser", false);
        }
    }

    isLogins(callback: boolean) {
        if (callback == null)
            throw ("UserLoginService: Callback in isAuthenticated() cannot be null");

        let cognitoUser = this.cognitoUtil.getCurrentUser();

        if (cognitoUser != null) {
            cognitoUser.getSession(function (err, session) {
                if (err) {
                    // console.log("UserLoginService: Couldn't get the session: " + err, err.stack);
                    //callback.isLoggedIn(err, false);
                    //this._sharedService.changeMessage(false);
                    return false;
                }
                else {
                    //  console.log("UserLoginService: Session is " + session.isValid());
                    //callback.isLoggedIn(err, session.isValid());
                    //this._sharedService.changeMessage(true);
                    return true;
                }
            });
        } else {
            //  console.log("UserLoginService: can't retrieve the current user");
            //callback.isLoggedIn("Can't retrieve the CurrentUser", false);
            //this._sharedService.changeMessage(false);
            return false;
        }
    }


    newPassword(newPasswordUser: NewPasswordUser, callback: CognitoCallback): void {
        // Get these details and call
        //cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, this);

        let authenticationData = {
            Username: newPasswordUser.username,
            Password: newPasswordUser.existingPassword,
        };
        let authenticationDetails = new AuthenticationDetails(authenticationData);

        let userData = {
            Username: newPasswordUser.username,
            Pool: this.cognitoUtil.getUserPool()
        };

        let cognitoUser = new CognitoUser(userData);
        cognitoUser.authenticateUser(authenticationDetails, {
            newPasswordRequired: function (userAttributes, requiredAttributes) {
                // User was signed up by an admin and must provide new
                // password and required attributes, if any, to complete
                // authentication.


                // the api doesn't accept this field back
                delete userAttributes.email_verified;
                cognitoUser.completeNewPasswordChallenge(newPasswordUser.password, requiredAttributes, {
                    onSuccess: function (result) {
                        callback.cognitoCallback(null, userAttributes);
                    },
                    onFailure: function (err) {
                        callback.cognitoCallback(err, null);
                    },
                    mfaSetup: (challengeName, challengeParameters) => {
                        callback.cognitoCallback(challengeName, null)
                    }
                });
            },
            onSuccess: function (result) {
                callback.cognitoCallback(null, result);
            },
            onFailure: function (err) {
                callback.cognitoCallback(err, null);
            },
            mfaSetup: (challengeName, challengeParameters) => {
                callback.cognitoCallback(challengeName, null)
            }
        });
    }

    forgotPassword(username: string, callback: CognitoCallback) {
        let userData = {
            Username: username,
            Pool: this.cognitoUtil.getUserPool()
        };
        let cognitoUser = new CognitoUser(userData);
        cognitoUser.forgotPassword({
            onSuccess: function () {
            },
            onFailure: function (err) {
                callback.cognitoCallback(err.message, null);
            },
            inputVerificationCode() {
                callback.cognitoCallback(null, null);
            }
        });
    }

    confirmNewPassword(email: string, verificationCode: string, password: string, callback: CognitoCallback) {
        let userData = {
            Username: email,
            Pool: this.cognitoUtil.getUserPool()
        };
        let cognitoUser = new CognitoUser(userData);
        cognitoUser.confirmPassword(verificationCode, password, {
            onSuccess: function () {
                callback.cognitoCallback(null, null);
            },
            onFailure: function (err) {
                callback.cognitoCallback(err.message, null);
            }
        });
    }

    renovarToken(): string {
        let tokenUsuario;
        var data = {
            UserPoolId: this.cognitoUtil.getUserPool().getUserPoolId(), // Your user pool id here
            ClientId: this.cognitoUtil.getUserPool().getClientId() // Your client id here
        };
        var userPool = new CognitoUserPool(data);
        var cognitoUser = userPool.getCurrentUser();
        if (cognitoUser != null) {
            cognitoUser.getSession(function (err, session) {
                if (err) {
                    return;
                }
                AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: 'us-west-2:7a03f2fe-4099-4ef0-b8df-0a1d1a980a8d',// your identity pool id here
                    Logins: {
                        'cognito-idp.us-west-2.amazonaws.com/us-west-2_oUDeQcE5u': session.getIdToken().getJwtToken(),
                    }
                });

                tokenUsuario = session.getIdToken().getJwtToken();
            });
        }
        return tokenUsuario;
    }

    logout() {
        this.cognitoUtil.getCurrentUser().signOut();
        localStorage.clear();
    }


}
