export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'DASHBOARDS': 'Dashboards',
            'CALENDAR': 'Calendar',
            'ECOMMERCE': 'E-Commerce',
            'ACADEMY': 'Academy',
            //
            'ADMIN': 'Admin',
            'NOMINA': 'Payroll',
            'PAGO': 'Pago',
            'REPORTE': 'Transactional Report',
            'BANCO': 'Bank',
            'BALANCE': 'Funds Balance',
            //
            'CHAT': 'Chat',
            'CONTACTS': 'Contacts',
            'DASH': {
                'RESUMEN': 'Summary',
            },
            'NOM': {
                'CARGA': 'Upload Payroll File',
                'LISTA': 'List Payrolls',
                'APROBAR': 'Approve Payrolls'
            },
            'REP': {
                'TRANSACCION': 'Transaction report',
                'OPERATIVO': 'Operative report',
                'DESCARGABLE': 'Downloadable report'
            },
            'BAN': {
                'ENVIO': 'Sending to Bank',
                'RESPUESTA': 'Bank Response',
                'RESPUESTA_MANUAL': 'Manual Bank Response',
                'CARGAR_CONSTANCIA': 'Upload proof',
                'ACTUALIZAR_ESTADO': 'Update Status'
            },
            'BALAN': {
                'REGISTRO': 'Movement record',
                'CONSULTA': 'Funds Balance Enquiry',
                'DETAIL': 'Detailed Balance'
            },
            'ADM': {
                'USUARIOS': 'Users',
                'COMERCIOS': 'Merchants',
                'ROLES': 'Roles',
                'GRUPO_COMERCIOS': 'Merchants Groups',
                'COMERCIO': 'merchant data',
                'TARIFAS': 'Rates Configuration'
            }
        },

        'WIDGET_SUPPORT': {
            'WELCOME_MESSAGE': 'Welcome to Monnet support services',
            'CONTACT_US': 'Contact us',
            'NAME': 'Name',
            'EMAIL': 'Email',
            'SUBJECT': 'Subject',
            'DESCRIPTION': 'Description',
            'MAX_FILE_SIZE_MESSAGE': 'The file exceeds the maximum size allowed (5MB)',
            'MAX_FILE_QUANTITY_MESSAGE': 'The maximum number of files allowed is 5',
            'SUPPORT': 'Support',
            'SENDING': 'Sending request...',
            'SEND': 'Send',
            'MAX_FILE_INFO': 'Upload files (max 5)',
            'FILE_CLICK': 'Click here to add any file',
            'COMPLETE_REQUIRED_FIELD': 'Complete fields requirements',
            'SUCCESS_MESSAGE': 'Your request has been sent successfully',
            'ERROR_MESSAGE': 'An error occurred while sending your request',
            'FILE_ALLOWED': 'Allowed files',
            'ATACHED_FILES': 'Attached files',
        },

        'ELEM_GENERIC': {
            'BUSCAR': 'Search',
            'GUARDAR': 'Save',
            'GENERAR': 'Generate',
            'BUSCAR_X_ID_NOMINA': 'Payroll ID',
            'MONEDA': 'Currency',
            'BANCO': 'Bank',
            'FECHA_INI': 'Start Date',
            'FECHA_FIN': 'End Date',
            'FECHA': 'Date',
            "TOTAL": 'Total',
            'ESTADO': 'State',
            'DIA_DE_HOY': 'Today',
            'DIA_DE_HOY_2': 'Today',
            'DESCARGAR_EXCEL': 'Download as Excel File',
            'SUBIR_ARCHIVO': 'Upload File',
            'DESCARGAR_CSV': 'Download as Csv File',
            'PERFIL': 'Profile',
            'ESPERE': 'Please wait ...',
            'ACCION': 'Action',
            'TRANSFERENCIA_BANCARIA': 'Bank Transfer',
            'DESELECT_ALL': 'Deselect all',
            'SELECT_ALL': 'Select all',
            'ALL': 'All',
            'PORTAPELES_ALERT': 'Copied to clipboard',
        },

        'MENSAJES_MODAL': {
            'NOMINA_VALIDA': {
                '1': 'Payroll ID N°: ',
                '2': 'Validated: ',
                '3': 'Errors: ',
                'INS_SALDO': 'The amount of this payroll exceeds the balance of your business, please check your balance and contact the operations team to manage the transfer of funds',
            },
            'NOMINA_CARGA': {
                '1': 'Number of running process: ',
                '2': 'Wait for your validation ...',
            }
        },

        'PERFIL': {
            'TITULO': 'Profile',
            'DATOS_USUARIO': 'User Data',
            'APELLIDO': 'Last Names',
            'NOMBRE': 'First Names',
            'CAMBIO_CONTRASENIA': 'Change Password',
            'CONTRASENIA_ACTUAL': 'Current Password',
            'NUEVA_CONTRASENIA': 'New Password',
            'CAMBIAR_CONTRASENIA': 'Change Password',
        },

        'DASHBBOARD': {
            'CANT_TOTAL_TRANS': 'Total Count of Transactions',
            'MONTO_TOTAL_TRANS': 'Total Amount of Transactions',
            'BALANCE_ACUMULADO': 'Accumulated Balance',
            'MONTO_TOTAL_TRANS_BANCO_EXITO': 'Total Amount of Bank Successful Transactions',
            'MON_TOT_TRANS_BANCO_EXITO': {
                'MONTO_INGRESO': 'Deposit Amount',
                'MONTO_EGRESO': 'Withdrawal Amount',
                'MONTO_SALDO': 'Balance'
            }
        },
        'NOMIMA': {
            'CARGA': {
                'TITULO': 'Upload Payroll File',
                'MONEDA': 'Currency',
                'FORMATO': 'Payroll Format',
                'ELIJA': 'Choose a file',
                'CARGAR': 'Load',
                'CARGANDO': 'Loading ...'
            },
            'LISTA': {
                'TITULO': 'List Payrolls',
                'SUBTITULO': 'List Payrolls',
                'TABLE_ID': 'ID',
                'TABLE_ARCHIVO': 'File',
                'TABLE_PROCESO': 'Process',
                'TABLE_SUBIDO_POR': 'Uploaded By',
                'TABLE_ESTADO': 'Status',
                'TABLE_MONEDA': 'Currency',
                'TABLE_TOTAL': 'Total Amount',
                'TABLE_TOTAL_#': 'Total #',
                'TABLE_FECHA_CARGA': 'Upload Date',
                'TABLE_ULT_ACTUALIZACION': 'Last Update',
                'TABLE_GRUPO': 'Merchant',
                'TABLE_PAIS': 'Country',
                'TABLE_ACCIONES': 'Actions',
                'ACCION_ORIGINAL': 'Original',
                'ACCION_VALIDADO': 'Validated',
                'ACCION_ERROR': 'Error',
                'ACCION_GENERAL': 'Consolidated',
                'NOMINA_ORIGINAL': {
                    'TITULO': 'Payroll Details',
                    'SUBTITULO': 'Transactions',
                    'TABLE_TRANS_NRO': 'Transaction N°',
                    'TABLE_ORDEN_COMPRA': 'Purchase Order',
                    'TABLE_NOTA_CREDITO': 'Credit Note',
                    'TABLE_CUENTA_DEST': 'Destination Account',
                    'TABLE_TIPO_CUENTA': 'Account Type',
                    'TABLE_BANCO': 'Bank',
                    'TABLE_MONTO_USD': 'Amount in dollars',
                    'TABLE_IDTYPE_CLIENTE': 'Document Type',
                    'TABLE_SHOPPER_ID': 'ID Client',
                    'TABLE_ID_CLIENTE': 'Document',
                    'TABLE_NOMBRE': 'Customer',
                    'TABLE_DEPARTAMENTO': 'Department',
                    'TABLE_CCI': 'CCI Account',
                    'TABLE_MONEDA': 'Currency',
                    'TABLE_MONTO': 'Amount',
                    'TABLE_CANAL': 'Channel',
                    'TABLE_CORREO': 'Email',
                    'TABLE_ESTADO': 'Status',
                    'TABLE_ESTADO_BANCO': 'Bank Status',
                    'TABLE_OBS_BANCO': 'Bank Status Details',
                    'TABLE_DATE': 'Transaction date',
                    'TABLE_USERNAME': 'Username',
                    'TABLE_ACCOUNT_NUMBER': 'User ID',
                    'TABLE_REFERENCE': 'Reference',
                    'TABLE_KYCYN': 'KyCyn',
                    'TABLE_CUSTOMER_CELLPHONE': 'Cellphone',
                    'TABLE_MERCHANT': 'Merchant',
                    'TABLE_TIPO_OPERACION': 'Operation Type',
                    'TABLE_CODOPERBANCO': 'Operation',
                    'TABLE_CUENTA': 'Account',
                    'TABLE_TRANS_OC': 'Transaction OC',
                    'TABLE_TRANS_ID': 'TransID',
                },
                'NOMINA_VALIDADO': {
                    'TITULO': 'Payroll Details',
                    'SUBTITULO': 'Transactions',
                    'TABLE_TRANS_NRO': 'Transaction N°',
                    'TABLE_ORDEN_COMPRA': 'Purchase Order',
                    'TABLE_NOTA_CREDITO': 'Credit Note',
                    'TABLE_CUENTA_DEST': 'Destination Account',
                    'TABLE_TIPO_CUENTA': 'Account Type',
                    'TABLE_BANCO': 'Bank',
                    'TABLE_SHOPPER_ID': 'ID Shopper',
                    'TABLE_ID_CLIENTE': 'Client ID',
                    'TABLE_NOMBRE': 'Customer Name',
                    'TABLE_DEPARTAMENTO': 'Department',
                    'TABLE_MONEDA': 'Currency',
                    'TABLE_MONTO': 'Amount',
                    'TABLE_CANAL': 'Channel',
                    'TABLE_CORREO': 'Email',
                    'TABLE_ESTADO': 'Status',
                    'TABLE_ESTADO_BANCO': 'Bank Status',
                    'TABLE_OBS_BANCO': 'Bank Status Details',
                    'TABLE_TIPO_OPERACION': 'Operation Type'
                },
                'NOMINA_ERROR': {
                    'TITULO': 'Payroll Detail Error',
                    'SUBTITULO': 'Failed transactions',
                    'TABLE_TRANS_NRO': 'Transaction N°',
                    'TABLE_ORDEN_COMPRA': 'Purchase Order',
                    'TABLE_NOTA_CREDITO': 'Credit Note',
                    'TABLE_CUENTA_DEST': 'Destination Account',
                    'TABLE_TIPO_CUENTA': 'Account Type',
                    'TABLE_BANCO': 'Bank',
                    'TABLE_SHOPPER_ID': 'ID Shopper',
                    'TABLE_ID_CLIENTE': 'Client ID',
                    'TABLE_NOMBRE': 'Customer',
                    'TABLE_DEPARTAMENTO': 'Department',
                    'TABLE_CCI': 'CCI Account',
                    'TABLE_MONTO_USD': 'Amount in dollars',
                    'TABLE_MONEDA': 'Currency',
                    'TABLE_MONTO': 'Amount',
                    'TABLE_CANAL': 'Channel',
                    'TABLE_CORREO': 'Email',
                    'TABLE_DETALLE_ERROR': 'Error Detail',
                    'TABLE_TIPO_OPERACION': 'Operation Type',
                    'TABLE_CUENTA': 'Account',
                    'TABLE_MOTIVO_RECHAZO': 'Reason for rejection',
                    'TABLE_OBSERVACION': 'Observation'
                }

            },
            'APROBAR': {
                'TITULO': 'Approve Payrolls',
                'SUBTITULO': 'Approve Payrolls',
                'TABLE_ID': 'ID',
                'TABLE_ARCHIVO': 'File',
                'TABLE_SUBIDO_POR': 'Uploaded By',
                'TABLE_ESTADO': 'State',
                'TABLE_MONEDA': 'Currency',
                'TABLE_TOTAL': 'Total Amount',
                'TABLE_TOTAL_#': '# Total',
                'TABLE_FECHA_CARGA': 'Load Date',
                'TABLE_ULT_ACTUALIZACION': 'Last Update',
                'TABLE_GRUPO': 'Group',
                'TABLE_PAIS': 'Country',
                'TABLE_ACCIONES': 'Actions'
            },
            'BASE_EXPORT_FILE': {
                'FILE_NAME': 'Report',
                'COLUMN_TRANS_ID': 'TransID',
                'COLUMN_TRANS_OC': 'Transaction OC',
                'COLUMN_FECHA_TRANSACCION': 'Transaction Date',
                'COLUMN_NOMBRE_USUARIO': 'Username',
                'COLUMN_ID_USUARIO': 'User ID',
                'COLUMN_MONTO': 'Amount',
                'COLUMN_MONEDA': 'Currency',
                'COLUMN_MONTO_DOLAR': 'Amount in dollars',
                'COLUMN_COMERCIO': 'Merchant',
                'COLUMN_BANCO': 'Bank',
                'COLUMN_NOMBRE_CLIENTE': 'Customer',
                'COLUMN_TIPO_CUENTA': 'Account Type',
                'COLUMN_CUENTA_DESTINO': 'Destination Account',
                'COLUMN_TIPO_ID_CLIENTE': 'Document Type',
                'COLUMN_ID_CLIENTE': 'Document',
                'COLUMN_DEPARTAMENTO': 'Departament',
                'COLUMN_NRO_CCI': 'CCI Account',
                'COLUMN_REFERENCIA': 'Reference',
                'COLUMN_KYCYN': 'KyCyn',
                'COLUMN_CORREO': 'Email',
                'COLUMN_CELULAR': 'Cellphone',
                'COLUMN_NOTA_CREDITO': 'Credit Note',
                'COLUMN_ESTADO': 'Status',
                'COLUMN_ESTADO_BANCO': 'Bank Status',
                'COLUMN_OBSERVACIONES_BANCO': 'Bank Status Details',
                'COLUMN_CANAL': 'Channel',
                'COLUMN_ARCHIVO': 'File',
                'COLUMN_PROCESO': 'Process',
                'COLUMN_SHOPPER_ID': 'ID Shopper',
                'COLUMN_DETALLE_ERROR': 'Error Detail',
                'COLUMN_MOTIVO_RECHAZO': 'Reason for rejection',
                'COLUMN_OBSERVACION': 'Observation'
            }
        },
        'REPORTE': {
            'OPERATIVO': {
                'TITULO': 'Operative Report',
                'TABLE_COMMERCE': 'Commerce',
                'TABLE_AGRUPACION_ID': 'Group ID',
                'TABLE_MONTO_VALIDADO': 'Validated Amount',
                'TABLE_MERCHANT': 'Merchant',
                'TABLE_REJECTEDTYPEBANK': 'Rejection type',
                'TABLE_NRO_OPERACION': 'Op. Number',
                'TABLE_BATCH_MERCHANT': 'Batch'
            },
            'DESCARGABLE': {
                'TITULO': 'Downloadable Report',
                'TABLE_ARCHIVO_NOMBRE': 'Name Report',
                'TABLE_QUANTITY': 'Quantity',
                'TABLE_REPORT_TYPE': 'Type Report',
                'TABLE_FROM_DATE': 'From',
                'TABLE_TO_DATE': 'To',
                'TABLE_CREATION_DATE': 'Creation'
            },
            'BASE': {
                'TITULO': 'Transactional Report',
                'TABLE_NOMINA_NOMBRE': 'Payroll Name',
                'TABLE_NOMINA_ID': 'Payroll ID',
                'DUE_DATE': 'Due Date',
                'TABLE_GRUPO': 'Merchant',
                'TABLE_PAIS': 'Country',
                'TABLE_TRANS_OC': 'Transaction ID',
                'TABLE_TRANS_NC': 'Merchant User ID',
                'TABLE_NRO_CUENTA': 'Payment Account',
                'TABLE_BANCO': 'Bank',
                'TABLE_BANCO_DESTINO': 'Destination Bank',
                'TABLE_TIPO_DOCUMENTO': 'Shopper Document Type',
                'TABLE_NRO_DOCUMENTO': 'Customer ID',
                'TABLE_BENEFICIARIO': 'Beneficiary',
                'TABLE_NOMBRE': 'Customer Name',
                'TABLE_MONEDA': 'Currency',
                'TABLE_MONTO': 'Amount',
                'TABLE_FECHA_MODIF': 'Last Update',
                'TABLE_ESTADO': 'Payroll Status',
                'TABLE_ESTADO_BANCO': 'Bank Status',
                'TABLE_OBS_BANCO': 'Bank Status Details',
                'TABLE_TIPO_RECHAZO': 'Rejection type',
                'TABLE_DEPARTAMENTO': 'Department',
                'TABLE_FECHA_CARGA': 'Uploading Date',
                'TABLE_FECHA_APROV': 'Approval Date',
                'TABLE_FECHA_ATENCION': 'Processing Date',
                'TABLE_FECHA_CIERRE': 'Bank Update',
                'TABLE_INTERPLAZA': 'Commis. Interplaza',
                'TABLE_TARIFA': 'Fee',
                'TABLE_IMPUESTO': 'Tax',
                'TABLE_CODOPERBANCO': 'Cod. Op. Bank',
                'TABLE_CCINUMBER': 'CCI Number',
                'TABLE_SHOPPER_ID': 'ID Shopper',
                'TABLE_MENSAJE_ERROR': 'Observation Reject',
                'TABLE_REFERENCE': 'Reference',
                'TABLE_MERCHANT': 'Merchant',
                'TABLE_FEE_TAX': 'Total Fee + Tax',
                'TABLE_NET_MERCHANT': 'Net-To-Merchant',
                'TABLE_BATCH_MERCHANT': 'Batch'

            },
            'BASE_EXPORT_FILE': {
                'FILE_NAME': 'Report',
                'COLUMN_GRUPO': 'Merchant',
                'COLUMN_PAIS': 'Country',
                'COLUMN_NOMINA_NOMBRE': 'Payroll Name',
                'COLUMN_ID_NOMINA': 'Payroll ID',
                'DUE_DATE': 'Due Date',
                'COLUMN_TRANSACCION_OC': 'Transaction OC',
                'COLUMN_TRANSACCION_NC': 'Merchant User ID',
                'COLUMN_NRO_CUENTA': 'Payment Account',
                'COLUMN_NRO_CUENTA_ABONO': 'Payment Account Number',
                'COLUMN_TIPO_CUENTA': 'Account type',
                'COLUMN_BANCO': 'Bank',
                'COLUMN_BANCO_ORI': 'Source Bank',
                'COLUMN_BANCO_DES': 'Destination Bank',
                'COLUMN_NRO_CCI': 'CCI number',
                'COLUMN_TIPO_DOCUMENTO': 'Document type',
                'COLUMN_NRO_DOCUMENTO': 'Customer ID',
                'COLUMN_NOMBRE': 'Name',
                'COLUMN_CORREO': 'Email',
                'COLUMN_CELULAR': 'Cellphone',
                'COLUMN_MONTO': 'Amount',
                'COLUMN_MONEDA': 'Currency',
                'COLUMN_USUARIO_CARGA': 'Loader',
                'COLUMN_FECHA_MODIF': 'Last Update',
                'COLUMN_ESTADO_BANCO': 'Bank status',
                'COLUMN_ESTADO': 'Payroll Status',
                'TIPO_RECHAZO': 'rejection type',
                'BENEFICIARIO': 'Beneficiary',
                'COLUMN_OBSERVACION_BANCO': 'Bank Status Details',
                'COLUMN_TARIFA': 'Fee',
                'COLUMN_IMPUESTO': 'Tax',
                'COLUMN_COD_OP_BANCO': 'Cod. Op. Bank',
                'COLUMN_DEPARTAMENTO': 'Departament',
                'COLUMN_COMISION_INTERPLAZA': 'Commis. Interplaza',
                'COLUMN_FECHA_CARGA': 'Uploading Date',
                'COLUMN_FECHA_APROV': 'Approval Date',
                'COLUMN_FECHA_ATENCION': 'Processing Date',
                'COLUMN_FECHA_CIERRE': 'Bank Update',
                'COLUMN_SHOPPER_ID': 'ID Shopper',
                'COLUMN_MENSAJE_ERROR': 'Observation Reject',
                'COLUMN_MOTIVO_RECHAZO': 'Reason Reject',
                'COLUMN_REFERENCE': 'Reference',
                'COLUMN_MERCHANT': 'Merchant',
                'COLUMN_FEE_TAX': 'Total Fee + Tax',
                'COLUMN_NET_MERCHANT': 'Net-To-Merchant',
                'COLUMN_OPERATIVO_MERCHANT': 'Merchant',
                'COLUMN_COMMERCE': 'Commerce',
                'COLUMN_ID_AGRUPACION': 'Group ID',
                'COLUMN_MONTO_VALIDADO': 'Validated Amount',
                'COLUMN_OPERATION_NUMBER': 'Operation Number',
                'COLUMN_BATCH_MERCHANT': 'Batch'
            }
        },
        'BANCO': {
            'ENVIO': {
                'TITULO': 'Sending to Bank',
                'SUBTITULO': 'Payroll list to be sent',
                'TABLE_ID': 'ID',
                'TABLE_GRUPO': 'Group',
                'TABLE_PAIS': 'Country',
                'TABLE_BANCO': 'Bank',
                'TABLE_MONEDA': 'Currency',
                'TABLE_TIPO_TRANSF': 'Transfer Type',
                'TABLE_TOTAL': 'Total',
                'TABLE_TOTAL_#': '# Total',
                'TABLE_FECHA_CREACION': 'Creation Date',
                'TABLE_FECHA_ACTUALIZACION': 'Update Date',
                'TABLE_ACCIONES': 'Actions',
                'TABLE_H2H': 'Host Transfer',
                'TABLE_SUBNOMINA': 'Subnómina'

            },
            'RESP_BANCO': {
                'TITULO': 'Bank Response',
                'SUBTITULO': 'List of answers',
                'TABLE_ID': 'ID',
                'TABLE_GRUPO': 'Group',
                'TABLE_PAIS': 'Country',
                'TABLE_ESTADO': 'State',
                'TABLE_BANCO': 'Bank',
                'TABLE_MONEDA': 'Currency',
                'TABLE_TOTAL': 'Total',
                'TABLE_TOTAL_#': '# Total',
                'TABLE_FECHA_CARGA': 'Load Date',
                'TABLE_SUBIR_ARCHIVO': 'Upload File',
                'TABLE_ACCIONES': 'Actions',
                'TABLE_ACCIONES_2': 'Actions'

            },
            'RESP_MANUAL_BANCO': {
                'TITULO': 'Manual Bank Response',
                'SUBTITULO': 'List of payrolls to be answered',
                'TABLE_ID': 'ID',
                'TABLE_GRUPO': 'Group',
                'TABLE_PAIS': 'Country',
                'TABLE_ESTADO': 'State',
                'TABLE_BANCO': 'Bank',
                'TABLE_MONEDA': 'Currency',
                'TABLE_MONTO': 'Amount',
                'TABLE_TOTAL_#': '# Total',
                'TABLE_FECHA_CREACION': 'Creation Date',
                'TABLE_FECHA_MODIF': 'Modification Date',
                'TABLE_ACCIONES': 'Actions'
            },
            'RESP_BANCO_AGRUPACION': {
                'TITULO': 'Cluster bank response',
                'SUBTITULO': 'List of clusters',
                'TABLE_ID': 'ID',
                'TABLE_IDENTIFIER': 'Group identifier',
                'TABLE_IDS': 'ID Payrolls',
                'TABLE_GRUPO': 'Group',
                'TABLE_PAIS': 'Country',
                'TABLE_ESTADO': 'State',
                'TABLE_BANCO': 'Bank',
                'TABLE_MONEDA': 'Currency',
                'TABLE_DELIVERY': 'Delivery',
                'TABLE_TOTAL': 'Total',
                'TABLE_TOTAL_#': '# Total',
                'TABLE_FECHA_CARGA': 'Load Date',
                'TABLE_ARCHIVO_H2H': 'File Name H2H',
                'TABLE_SUBIR_ARCHIVO': 'Upload File',
                'TABLE_ARCHIVO_BANCO': 'Bank File',
                'TABLE_TIPO': 'Process Type',
                'TABLE_ACCIONES': 'Actions',
                'TABLE_ACCIONES_2': 'Actions'

            },
            'CONSTANCIA': {
                'TITULO': 'Records',
                'SUBTITULO': 'List of Records',
                'TABLE_ID': 'ID',
                'TABLE_ID_NOMINA': 'Payroll Id',
                'TABLE_GRUPO_NOMINA': 'Payroll group Id',
                'TABLE_GRUPO': 'Group',
                'TABLE_PAIS': 'Country',
                'TABLE_ESTADO': 'State',
                'TABLE_FECHA_CREACION': 'Creation Date',
                'TABLE_NOMBRE_ARCHIVO': 'Filename',
                'TABLE_ACCIONES': 'Actions'
            },
            'BASE_EXPORT_FILE': {
                'FILE_NAME': 'Report',
                'COLUMN_NOMBRE': 'name',
                'COLUMN_APELLIDO': 'lastname',
                'COLUMN_CUIL': 'cuit',
                'COLUMN_MAIL': 'email',
                'COLUMN_CBU': 'CBU',
                'COLUMN_CVU': 'CVU',
                'COLUMN_ALIAS': 'ALIAS',
                'COLUMN_FECHA': 'date',
                'COLUMN_CONCEPTO': 'concept',
                'COLUMN_TIPO_CUENTA': 'Account Type',
                'COLUMN_IMPORTE': 'Amount'
            },
            'RESP_ACTUALIZAR_ESTADO': {
                'TITULO': 'Update status',
                'TABLE_NOMINA_ID': 'Payroll ID',
                'TABLE_GRUPO': 'Merchant',
                'TABLE_PAIS': 'Country',
                'TABLE_TRANS_OC': 'Transaction ID',
                'TABLE_BANCO': 'Bank',
                'TABLE_NRO_DOCUMENTO': 'Customer ID',
                'TABLE_NOMBRE': 'Customer Name',
                'TABLE_MONEDA': 'Currency',
                'TABLE_MONTO': 'Amount',
                'TABLE_ESTADO': 'Payroll Status',
                'TABLE_OBS_BANCO': 'Bank Status Details',
                'TABLE_ACCIONES': {
                    'ACTUALIZAR': 'Change',
                    'NOTIFICAR': 'Notify',
                    'RESEND': 'Resend OTP'
                },
                'MODAL': {
                    'TITULO': 'Change Status',
                    'GRUPO': 'Merchant',
                    'TRANS_OC': 'Transaction ID',
                    'NOMINA_ID': 'Payroll ID',
                    'MONTO': 'Amount',
                    'NOMBRE': 'Customer Name',
                    'ESTADO': 'Payroll Status',
                    'FECHA': ' Authorization date',
                    'GUARDAR': 'Save',
                    'DESCRIPCION': 'Reason / Detail'
                }
            }
        },
        'BALANCE': {
            'REGISTRO': {
                'TITULO': 'Movement record',
                'TABLE_GRUPO': 'Group',
                'TABLE_PAIS': 'Country',
                'TABLE_FECHA_DEPOSITO': 'Movement date',
                'TABLE_DESCRIPCION': 'Description',
                'TABLE_BANCO': 'Bank',
                'TABLE_TIPO': 'Movement',
                'TABLE_TIPO_REGISTRO': 'Record Type',
                'TABLE_NRO_DOCUMENT': 'No Document',
                'TABLE_NRO_CUENTA': 'Account Number',
                'TABLE_MONEDA': 'Currency',
                'TABLE_MONTO': 'Amount',
                'TABLE_ACCIONES': 'Actions'
            },
            'CONSULTA': {
                'TITULO': 'Funds Balance Enquiry',
                'TABLE_GRUPO': 'Merchant',
                'TABLE_PAIS': 'Country',
                'TABLE_BANCO': 'Bank',
                'TABLE_MONEDA': 'Currency',
                'TABLE_TOTAL_ING': 'Total Deposits',
                'TABLE_TOTAL_EGR': 'Total Withdrawals',
                'TABLE_SALDO_TOTAL': 'Period Balance',
                'TABLE_SALDO_ANTERIOR': 'Previous Balance',
                'TABLE_SALDO_ACUMULADO': 'Total Balance'
            },
            'DETAIL': {
                'TITULO': 'Detailed Balance',
                'TABLE_FECHA': 'Date',
                'TABLE_DESCRIPCION': 'Details',
                'TABLE_BANCO': 'Bank',
                'TABLE_TIPO': 'Movement',
                'TABLE_ID_NOMINA': 'Payroll ID ',
                'TABLE_NOMBRE_NOMINA': 'Payroll Name',
                'TABLE_MONEDA': 'Currency',
                'TABLE_IMPORTE': 'Amount ',
                'TABLE_SALDO': 'Balance',
                'MENSAJE': {
                    'MSG_INGRESO': 'Payouts funding top-up',
                    'MSG_EGRESO': 'Successful Payouts',
                    'MSG_SALDO': 'Previous balance',
                }
            },
            'BASE_EXPORT_FILE': {
                'FILE_NAME': 'Report',
                'COLUMN_COMERCIO': 'Merchant',
                'COLUMN_FECHA_DEPOSITO': 'Deposit date',
                'COLUMN_BANCO': 'Bank',
                'COLUMN_MONEDA': 'Currency',
                'COLUMN_NRO_DOCUMENTO': 'No Document',
                'COLUMN_NRO_CUENTA': 'Account Number',
                'COLUMN_MONTO': 'Amount',
                'COLUMN_TIPO': 'Movement',
                'COLUMN_TIPO_REGISTRO': 'Record Type',
                'COLUMN_DESCRIPTION': "Description",
                'COLUMN_TOTAL_INGRESO': 'Total Deposits',
                'COLUMN_TOTAL_EGRESO': 'Total Withdrawals',
                'COLUMN_SALDO_TOTAL': 'Period Balance',
                'COLUMN_SALDO_ANTERIOR': 'Previous Balance',
                'COLUMN_SALDO_ACUMULADO': 'Total Balance',
                'COLUMN_PAIS': "Country"
            }
        },
        'ADMIN': {
            'LISTA_USUARIOS': {
                'TITULO': 'Users',
                'SUBTITULO': 'Users',
                'TABLE_NOM_USUARIO': 'Username',
                'TABLE_NOMBRE': 'Name',
                'TABLE_APELLIDO': 'Surname',
                'TABLE_GRUPO': 'Group',
                'TABLE_CORREO': 'Email',
                'TABLE_ROL': 'Role',
                'TABLE_ESTADO': 'State',
                'TABLE_ACCIONES': 'Actions',
                'MODAL': {
                    'TITULO_NUEVO_USU': 'New User',
                    'TITULO_MODIF_USU': 'Edit User',
                    'NOMBRE_DE_USU': 'Username *',
                    'CORREO_USU': 'Email *',
                    'NOMBRES_USU': 'First Names',
                    'APELLIDOS_USU': 'Last Names',
                    'DOCUMENTO_USU': 'Document Type',
                    'NRO_DOCUMENTO_USU': 'Document N°',
                    'GRUPO_USU': 'Group',
                    'ELEGIR_COMERCIO_USU': 'Select Merchant',
                    'ROL_USU': 'Role',
                    'HABILITAR_NOTIFICACION': 'Enable notification',
                    'GUARDAR_USU': 'Save',
                    'MODIFICAR_USU': 'Modify'
                }
            },
            'LISTA_COMERCIOS': {
                'TITULO': 'Merchants',
                'SUBTITULO': 'Merchants',
                'TABLE_COMERCIO': 'Commerce',
                'TABLE_NOM_CONTACTO': 'Contact Name',
                'TABLE_GRUPO': 'Group',
                'TABLE_RUC': 'RUC',
                'TABLE_CORREO': 'Mail',
                'TABLE_ESTADO': 'State',
                'TABLE_SUBIR_IMG': 'Upload Image',
                'TABLE_ACCIONES': 'Actions',
                'MODAL': {
                    'TITULO_NUEVO_COM': 'New Merchant',
                    'TITULO_MODIF_COM': 'Edit Merchant',
                    'TITULO_SUBIR_IMG_COM': 'Upload logo',
                    'NOMBRE_COM': 'Name *',
                    'NOMBRE_CONTACTO_COM': 'Contact Name',
                    'DIRECCION_COM': 'Address',
                    'NRO_RUC_COM': 'RUC N°',
                    'TELEFONO_COM': 'Telephone',
                    'CORREO_COM': 'E-mail',
                    'GRUPO_COM': 'Group',
                    'PAIS_COM': 'Country',
                    'JOB_WINDOW_TIME': 'Job Window Time',
                    'SELECCION_MONEDA_COM': 'Select Currencies',
                    'GUARDAR_COM': 'Save',
                    'MODIFICAR_COM': 'Modify'
                }
            },
            'LISTA_ROLES': {
                'TITULO': 'Roles',
                'SUBTITULO': 'Roles',
                'TABLE_NOMBRE': 'Name',
                'TABLE_NOM_CONTACTO': 'Contact Name',
                'TABLE_FECHA_CREACION': 'Creation Date',
                'TABLE_SUPER_ADMIN': 'Super Admin',
                'TABLE_ESTADO': 'State',
                'TABLE_ACCIONES': 'Actions',
                'MODAL': {
                    'TITULO_NUEVO_ROL': 'New Role',
                    'TITULO_MODIF_ROL': 'Edit Role',
                    'TITULO_PERMISOS_ROL': 'Permissions',
                    'NOMBRE_ROL': 'Name *',
                    'NOMBRE_VISTA_ROL': 'View Name',
                    'ADMINISTRADOR': 'Administrator',
                    'ESTADO': 'Status',
                    'GUARDAR_ROL': 'Save',
                    'MODIFICAR_ROL': 'Modify'
                }
            },
            'LISTA_GRUPOS_COMERCIO': {
                'TITULO': 'Merchants Groups',
                'SUBTITULO': 'Merchants Groups',
                'TABLE_NOMBRE': 'Name',
                'TABLE_NOM_CONTACTO': 'Contact Name',
                'TABLE_CORREO': 'Mail',
                'TABLE_TELEFONO': 'Telephone',
                'TABLE_ACCIONES': 'Actions',
                'MODAL': {
                    'TITULO_NUEVO_GRUPO': 'New Group',
                    'TITULO_MODIF_GRUPO': 'Edit Group',
                    'NOMBRE_GRUPO': 'Name *',
                    'NOMBRE_CONTACTO_GRUPO': 'Contact Name',
                    'TELEFONO_GRUPO': 'Telephone',
                    'CORREO_GRUPO': 'E-mail',
                    'GUARDAR_GRUPO': 'Save',
                    'MODIFICAR_GRUPO': 'Modify'
                }
            },
            'COMERCIO': {
                'TITULO': 'Merchant',
                'SUBTITULO': 'Data Merchant',
                'LLAVE_SHA': 'Key',
                'LISTA': {
                    'TABLA_ID': 'ID',
                    'TABLA_TIPO': 'Type',
                    'TABLA_KEY': 'Key',
                    'TABLA_ESTADO': 'State',
                    'TABLA_FECHA': 'Registration date',

                }
            },
            'TARIFAS': {
                'TITULO': 'Tarifas',
                'SUBTITULO': 'Rates List',
                'MERCHANT': 'Merchant',
                'RATE_TYPE': 'Type',
                'CURRENCY': 'Currency',
                'MAX_RATE': 'Maximum Rate',
                'MIN_RATE': 'Minumum Rate',
                'PERCENTAGE_RATE': 'Percentage',
                'STATUS': 'Status',
                'SAVE': 'Save',
                'CANCEL': 'Cancel',
                'NEW': 'New',
                'UPDATE': 'Update'
            }
        },
        'QUESTIONNAIRE': {
            'TITLE': 'Rate Your Experience',
            'SUBTITLE_1': 'Help us by taking our',
            'SUBTITLE_2': 'Satisfaction Survey NPS',
            'DATE': 'From June 1 to 30.',
            'ACTION': 'LET’S GET STARTED!>',
        },
        'MFA': {
            'MFA_SETUP_TITLE': 'MFA Setup',
            'ASSOCIATE_NEW_KEY': 'Associate new security key',
            'MFA_SETUP_1': 'Install one of the following applications on your mobile',
            'MFA_SETUP_2': 'Scan the QR code or enter the secret key below into your app',
            'MFA_SETUP_3': 'Enter the one time secret provided by the application and click Submit to finish the setup',
            'MFA_SETUP_ACTION': 'Submit',
            'MFA_SETUP_INPUT_PLACEHOLDER': 'Verification code',
            'MFA_SETUP_SUCCESS': 'MFA configured successfully',
            'MFA_SETUP_ERROR': 'Error configuring MFA',
            'MFA_SETUP_HINT': 'Please enter a valid verification code',
            "MFA_TWO_STEP_LABEL": "2-Step verification (MFA)"
        }
    }
};
