import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';

@Injectable({
    providedIn: 'root'
})
export class JwtService<T> {
    private jwtPayload: T

    set jwt(payload: T) {
        this.jwtPayload = payload
    }

    get jwt(): T {
        return this.jwtPayload
    }

    constructor() { }

    decode(token: string): void {
        this.jwt = this.decodeToken(token)
    }

    private decodeToken(token: string): T {
        //@ts-ignore
        if (!token) return null
        return jwtDecode(token)
    }
}
