import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  public grupoInitialSubject = new BehaviorSubject<any>('')
  public grupoInitial$ = this.grupoInitialSubject.asObservable();

  public grupoSubject = new BehaviorSubject<any>('')
  public grupo$ = this.grupoSubject.asObservable();

  //recibimos el dato
  private messageSource = new BehaviorSubject<any>('');
  currentMessage = this.messageSource.asObservable();

  constructor() { }

  //toma el valor que se esta enviando
  changeMessage(message: any) {
    this.messageSource.next(message)
  }

  private messageSourceMoneda = new BehaviorSubject<any>('');
  currentMessageMoneda = this.messageSourceMoneda.asObservable();


  //toma el valor que se esta enviando
  changeMessageMoneda(message: any) {
    this.messageSourceMoneda.next(message)
  }

  private messageSourceCgf = new BehaviorSubject<any>('');
  currentMessageCfg = this.messageSourceCgf.asObservable();


  //toma el valor que se esta enviando
  changeMessageCfg(message: any) {
    this.messageSourceCgf.next(message)
  }


}