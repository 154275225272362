import { Injectable } from '@angular/core';
import { AdminService } from '../admin.service';
import { UsuarioCrearModel } from 'app/main/models/usuarioCrearModel.model';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UsuariosService {
  merchantaNameSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  isAuthenticatedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private _adminService: AdminService) { }

  obtenerUsuarios(jsonsend) {
    return this._adminService.usuarios_listar(jsonsend);
  }

  agregarUsuario(cabecera: UsuarioCrearModel) {
    return this._adminService.usuarios_agregar(cabecera);
  }

  editarUsuario(editarUsuario: UsuarioCrearModel) {
    return this._adminService.usuario_editar(editarUsuario);
  }

  actualizarUsuario(actualizarUsuario: UsuarioCrearModel) {
    return this._adminService.usuario_actualizar(actualizarUsuario);
  }

  // Obtener Combo Comercio
  obtenerCboComercio() {
    return this._adminService.cboComercios_listar();
  }

  // Obtener Combo Rol
  obtenerCboRoles() {
    return this._adminService.cboRoles_listar();
  }

}
